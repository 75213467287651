* {
  margin: 0;
  padding: 0;
  font-family: Pretendard;
}

:root {
  /*color*/
  --red: #ff4444;
  --green: #18ee3a;
  --blue: #38b7ff;
  --yellow: #ffe819;
  --purple1: #8438ff;
  --purple2: #f5f1ff;
  --gray1: #c0c0c0;
  --gray2: #b5b5b5;
  --gray3: #d6d6d6;
  --white: #fff;
  --black: #303030;
}
